<template>
  <div>
    <h1>Adicionar tipo de ocorrência</h1>
    <v-form ref="createOccType" @submit.prevent="save">
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            :rules="requiredRules"
            label="Nome"
            v-model="occurrenceType.name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn :loading="saving" color="success" type="submit">Salvar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import http from "@/modules/http";
export default {
  data() {
    return {
      saving: false,
      occurrenceType: {
        name: "",
      },
      requiredRules: [(v) => !!v || "Este campo é obigatório"],
    };
  },
  methods: {
    save() {
      if (this.$refs.createOccType.validate()) {
        this.saving = true;
        http
          .post("api/occurrence-types", this.occurrenceType)
          .then((res) => {
            this.saving = false;
            console.log(res);
            this.$emit("occurrenceTypeSaved");
            this.occurrenceType.name = "";
            this.$side({
              type: "success",
              msg: "Salvo com sucesso",
              duration: 4000,
            });
            this.$refs.createOccType.reset();
          })
          .catch((err) => {
            this.saving = false;
            this.$side({
              type: "error",
              msg: "Erro ao salvar",
              duration: 4000,
            });
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
</style>