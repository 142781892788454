<template>
  <div>
    <h1>Editar tipo de ocorrência</h1>
    <v-form @submit.prevent="save">
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field label="Nome" v-model="occurrenceType.name"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn :loading="saving" color="success" type="submit">Atualizar</v-btn>
          <v-btn @click="$emit('cancelTypeUpdate')" class="ml-2" color="error">Cancelar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import http from '@/modules/http'
  export default {
    props: {
      type: {
        type: Object,
        default: null
      }
    },
    mounted() {
      this.occurrenceType = this.type
    },
    data() {
      return {
        saving: false,
        occurrenceType: {
          name: ''
        },
      }
    },
    methods: {
      save() {
        this.saving = true;
        http
          .put('api/occurrence-types/'+this.occurrenceType.id, this.occurrenceType)
          .then(res => {
            this.saving = false;
            console.log(res);
            this.$emit('occurrenceTypeUpdated');
            this.$side({
              type: 'success',
              msg: 'Salvo com sucesso',
              duration: 4000
            });
          }).catch(err => {
            this.saving = false;
            this.$side({
              type: 'error',
              msg: 'Erro ao salvar',
              duration: 4000
            });
            console.log(err);
          })
      }
    },
  }
</script>

<style scoped>

</style>