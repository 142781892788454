<template>
  <div>

    <div v-if="!updating">
      <create-type @occurrenceTypeSaved="getOccurrences" />
    </div>

    <div v-if="updating">
      <update-type @cancelTypeUpdate="getOccurrences" @occurrenceTypeUpdated="getOccurrences" :type="focusType" />
    </div>

    <h1>Tipos Ocorrências</h1>
    <v-data-table
      :loading="loading"
      :items="occurrenceTypes"
      :headers="headers"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="handleUpdate(item)" color="warning">mdi-pencil</v-icon>
        <v-icon :disabled="deleting" @click="deleteType(item)" color="error">{{deleting ? 'mdi-cancel' : 'mdi-delete'}}</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import http from '@/modules/http'
import CreateType from '../components/CreateType'
import UpdateType from '../components/UpdateType'
  export default {
    components: {
      CreateType,
      UpdateType,
    },
    data() {
      return {
        focusType: {},
        updating: false,
        loading: false,
        deleting: false,
        occurrenceTypes: [],
        headers: [
          {text: 'ID', value: 'id'},
          {text: 'Nome', value: 'name'},
          {text: 'Ações', value: 'actions'}
        ]
      }
    },
    methods: {
      handleUpdate(type) {
        this.focusType = type;
        this.updating = true;
      },
      deleteType(type) {
        this.$confirm({
          type: "error",
          title: "Excluir Tipo",
          content: "Tem certeza que deseja excluir este tipo de B.O?",
          confirmText: "Sim",
          cancelText: "Não",
          confirm: () => {
            this.deleting = true;
            http
              .delete("api/occurrence-types/"+type.id)
              .then((res) => {
                this.deleting = false;
                this.getOccurrences();
                this.$side({
                  type: "success",
                  msg: "Removido com sucesso",
                  duration: 4000,
                });
              })
              .catch((err) => {
                this.deleting = false;
                this.$side({
                  type: "error",
                  msg: "Erro ao remover",
                  duration: 4000,
                });
              });
            this.$close(); //If you don't want to close the dialog, you can not use it.
          },
          cancel: () => {
            this.$close(); //If you don't want to close the dialog, you can not use it.
          },
        });
      },
      getOccurrences() {
        this.updating = false;
        this.loading = true;
        http
          .get('api/occurrence-types')
          .then(res => {
            this.loading = false;
            this.occurrenceTypes = res.data;
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
          })
      }
    },
    mounted () {
      this.getOccurrences();
    },
  }
</script>

<style scoped>

</style>